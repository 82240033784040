<script>
import PaginationRow from "@components/global/PaginationRow";
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
export default {
  components: {PaginationRow},
  page: {title: 'Ενημερώσεις'},
  mixins: [PaginatedDataMixin],
  created(){
    this.api.url = "/notifications";
    this.getData();
    this.markAllAsRead();
  },
  methods: {
      markAllAsRead(){
          this.$axios.put(`/notifications/markAllAsRead`);
      },
  }
}

</script>
<style lang="scss">
    .pagination-footer{
        margin-bottom:30px!important;
    }
</style>
<template>
<div id="notifications-layout">
  <div v-if="api.rows.length > 0 " class="row">

      <b-alert v-if="api.rows.length === 0" show variant="warning">Δεν υπάρχουν ειδοποιήσεις!</b-alert>
      <div v-for="nf in api.rows" v-else :key="nf.id" class="col-lg-6">
          <div class="card notification-card">
              <div class="card-body ribbon-box">
                  <div class="ribbon bg-primary text-white px-1 py-1 rounded-pill float-right">
                      {{nf.updatedAt}}
                  </div>
                  <div class="card-title">{{nf.title}}</div>
                  <p class="card-text">{{nf.content}}</p>
              </div>
          </div>
      </div>
  </div>

  <pagination-row :api="api" @fetchData="getData"></pagination-row>


</div>
</template>
